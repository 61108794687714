<template>
  <div class="mem-view volunteer-view">
    <div class="mem-title">{{ $t("volunteer") }}</div>

    <div class="volunteer-card">
      <div class="card-content">
        <span class="card-title">{{ $t("volunteer_card_title") }}</span>
        <div class="card-body">
          <div class="card-description">
            <div class="card-subline">
              <i class="material-icons">done</i>
              <span>{{ $t("volunteer_card_point1") }}</span>
            </div>
            <div class="card-subline">
              <i class="material-icons">done</i>
              <span>{{ $t("volunteer_card_point2") }}</span>
            </div>
            <div class="card-subline">
              <i class="material-icons">done</i>
              <span>{{ $t("volunteer_card_point3") }}</span>
            </div>
          </div>

          <mem-button @click="openLink" v-if="volunteerLink">
            <span class="desk-el">{{ $t("volunteer") }}</span>
            <span class="mobile-el">{{ $t("go") }}</span>
          </mem-button>
        </div>
      </div>
      <div class="content-wrapper"></div>
      <div class="image-wrapper">
        <img
          src="https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/account/volunteer_card_background_desktop.webp"
          alt="volunteer"
          loading="lazy"
          class="desk-el"
        />
        <img
          src="https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/account/volunteer_card_background_mobile.webp"
          alt="volunteer"
          loading="lazy"
          class="mobile-el"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { appLinksParams } from "@/common/mixins";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  mixins: [appLinksParams],
  methods: {
    openLink() {
      let windowReference = window.open();
      windowReference.location = this.volunteerLink;
    },
  },
  watch: {
    showVolunteerView: {
      immediate: true,
      handler(val) {
        if (!val) this.$router.replace({ name: "home" });
      },
    },
  },
};
</script>
<style lang="scss" scoped>
$card-height: 180px;
$mobile-card-height: 226px;
$rotate-degrees: -58deg;

.volunteer-view {
  .mem-title {
    @include elementMarginBottom("M");
  }
  .desk-el {
    display: block;
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
  .mobile-el {
    display: none;
    @media screen and (max-width: $mobile) {
      display: block;
    }
  }

  .volunteer-card {
    position: relative;
    z-index: $zindex-page-element; // 45
    height: $card-height;
    background-color: #121212;
    max-width: 722px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    width: 100%;

    @media screen and (max-width: $mobile) {
      height: $mobile-card-height;
    }
    .card-content {
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 40px 40px 30px 48px;
      width: 100%;
      z-index: 30;
      @media screen and (max-width: $mobile) {
        padding: 24px 20px;
      }
      .card-title {
        font-family: "Druk Wide";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px; /* 70% */
        text-transform: uppercase;
        margin-bottom: 30px;
        @media screen and (max-width: $mobile) {
          line-height: 24px;
          margin-bottom: 16px;
          max-width: 198px;
        }
      }
      .card-body {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        @media screen and (max-width: $mobile) {
          flex-direction: column;
          align-items: flex-start;
        }
        .card-description {
          .card-subline {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px; /* 100% */
            text-transform: capitalize;
            display: flex;
            flex-direction: row;
            margin-bottom: 8px;
            @media screen and (max-width: $mobile) {
              font-size: 12px;
            }
            .material-icons {
              font-size: 14px;
              font-weight: bold;
              margin-right: 6px;
            }
          }
        }
        button {
          @media screen and (max-width: $mobile) {
            margin-top: 8px;
            padding-right: 32px;
            padding-left: 32px;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .content-wrapper {
      position: relative;
      // backface-visibility: hidden;
      // transform: translateZ(0);
    }
    .image-wrapper {
      backface-visibility: hidden; /* Убирает проблемы с рендерингом */
      transform: translateZ(0); /* Активирует аппаратное ускорение */
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &::before {
        content: "";
        position: absolute;
        top: 85px;
        left: -152px;
        width: 150%;
        height: 100%;
        background-color: #121212;
        transform: rotate($rotate-degrees);
        transform-origin: left top;
        pointer-events: none;
        z-index: 20;
        @media screen and (max-width: $mobile) {
          top: 85px;
          left: -220px;
          transform: rotate(-75deg);
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 80.73%
        );
        pointer-events: none;
        @media screen and (max-width: $mobile) {
          background: linear-gradient(
              259deg,
              rgba(0, 0, 0, 0) 55.56%,
              #121212 80.34%
            ),
            linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.4) 80.73%
            );
        }
      }
    }
  }
}
</style>